import Vue from 'vue'
import '@babel/polyfill'
import axios from 'axios';
import App from './App.vue'
import router from './router'
import 'mutationobserver-shim'
import './plugins/bootstrap-vue'
import '@/scss/custom.scss'
import store from '@/store/'

axios.defaults.baseURL = process.env.VUE_APP_MICROSERVICE_URL
axios.defaults.headers.common = {
  'Content-Type': 'application/json'
}

Vue.config.productionTip = false

// Configure Auth0 Plugin
import { Auth0Plugin } from '@pantheon-systems/auth0-vue-module'
import { domain, clientId, audience } from '../auth_config.json'

const connections = {
  localhost: 'pantheonadminokta',
  'acdn-toolkit-dev.ps-pantheon.com': 'pantheonadminokta',
  'acdn-toolkit-test.ps-pantheon.com': 'pantheonadminokta',
  'acdn-toolkit.ps-pantheon.com': 'pantheonadminokta',
  'acdn-toolkit-external-dev.ps-pantheon.com': 'DrupalSquad-SSO',
  'acdn-toolkit-external-test.ps-pantheon.com': 'DrupalSquad-SSO',
  'acdn-toolkit-external.ps-pantheon.com': 'DrupalSquad-SSO',
}

const preferredConnection = (Object.keys(connections).includes(document.domain))
  ? connections[document.domain]
  : 'pantheonadminokta'

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  connection: preferredConnection,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
