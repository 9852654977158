<template>
  <div id="navbar">
    <b-navbar toggleable="lg" type="dark" variant="dark">
      <b-navbar-brand :to='{name: "home"}' exact>
        <img src="../assets/Fist-White.svg" class="d-inline-block align-top" width="30">
        Advanced Global CDN Toolkit
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to='/' exact>Home</b-nav-item>
          <b-nav-item v-if="!$auth.loading && $auth.isAuthenticated" :to='{name: "settings"}' exact>Settings</b-nav-item>
          <b-nav-item-dropdown v-if="apiKey && (!$auth.loading && $auth.isAuthenticated)" text="Private Keys" :toggle-class="$route.path.indexOf('/private-keys') == 0 ? 'active' : ''">
            <b-dropdown-item :to='{name: "private-keys"}' exact>List</b-dropdown-item>
            <b-dropdown-item :to='{name: "upload-key"}'>Upload</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item-dropdown v-if="apiKey && (!$auth.loading && $auth.isAuthenticated)" text="Certificates" :toggle-class="$route.path.indexOf('/certificates') == 0 ? 'active' : ''">
            <b-dropdown-item :to='{name: "certificates"}' exact>List</b-dropdown-item>
            <b-dropdown-item :to='{name: "upload-cert"}'>Upload</b-dropdown-item>
          </b-nav-item-dropdown>
          <b-nav-item v-if="apiKey && (!$auth.loading && $auth.isAuthenticated)" :to='{name: "new-service"}' exact>Create New Service</b-nav-item>
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto" right>
          <b-nav-text v-if="$auth.loading" class="mr-2">Getting auth status...</b-nav-text>
          <b-nav-item v-if="!$auth.loading && !$auth.isAuthenticated" @click="login" class="mr-2">Log In</b-nav-item>
          <b-nav-item v-if="!$auth.loading && $auth.isAuthenticated" class="mr-2" :to='{name: "profile"}' exact>Profile</b-nav-item>
          <b-nav-item v-if="!$auth.loading && $auth.isAuthenticated" @click="logout" class="mr-2">Log Out</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: 'navbar',
  computed: {
    apiKey() {
      return this.$store.state.apiKey
    },
  },
  data() {
    return {}
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect()
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  },
}
</script>
