import EmptyRouterView from '@/components/EmptyRouterView.vue'

export default [
  {
    path: '/settings',
    component: EmptyRouterView,
    children: [{
      path: '',
      name: 'settings',
      component: () => import('../views/Settings.vue')
    }]
  }
]
