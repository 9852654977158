import EmptyRouterView from '@/components/EmptyRouterView'

export default [
  {
    path: '/new-service',
    component: EmptyRouterView,
    children: [{
      path: '',
      name: 'new-service',
      component: () => import('../views/CreateNewService.vue')
    }]
  }
]
