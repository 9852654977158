import { authGuard } from '@pantheon-systems/auth0-vue-module'
import EmptyRouterView from '@/components/EmptyRouterView.vue'

export default [
  {
    path: '/profile',
    component: EmptyRouterView,
    children: [{
      path: '',
      name: 'profile',
      component: () => import('../views/Profile.vue')
    }],
    beforeEnter: authGuard
  }
]
