import Vue from 'vue'
import Vuex from 'vuex'
import VueCookies from 'vue-cookies'

Vue.use(Vuex)
Vue.use(VueCookies)

// Check if the environment variable is set
let apiKey = null
let apiKeyFromEnv = false
if (process.env.VUE_APP_FASTLY_API_KEY) {
  apiKey = process.env.VUE_APP_FASTLY_API_KEY
  apiKeyFromEnv = true
} else {
  if (VueCookies.isKey('fastly-api-key')) {
    apiKey = VueCookies.get('fastly-api-key')
  }
}

const setCookieState = ({state, stateKey, newValue, cookieName, cookieDuration='30d'}) => {
  state[stateKey] = newValue
  VueCookies.set(cookieName, newValue, cookieDuration)
}

export default new Vuex.Store({
  state: {
    apiKey,
    apiKeyFromEnv,
    uploadType: 'paste',
  },
  mutations: {
    setApiKey(state, newApiKey) {
      setCookieState({
        state,
        stateKey: 'apiKey',
        newValue: newApiKey,
        cookieName: 'fastly-api-key'
      })
    },
    removeApiKey(state) {
      VueCookies.remove('fastly-api-key')
      if (!apiKeyFromEnv) {
        state.apiKey = null
      }
    },
    setUploadType(state, newUploadType) {
      if (newUploadType != 'paste' && newUploadType != 'upload') {
        throw "Invalid upload type; should be 'paste' or 'upload'."
      } else {
        state.uploadType = newUploadType
      }
    },
  },
  actions: {
    setApiKeyAction({commit}, payload) {
      commit('setApiKey', payload)
    },
    setUploadTypeAction({commit}, payload) {
      commit('setUploadType', payload)
    },
    clearSettingsAction({commit}) {
      commit('removeApiKey')
    },
  }
})
