import { authGuard } from '@pantheon-systems/auth0-vue-module'
import EmptyRouterView from '@/components/EmptyRouterView.vue'

export default [
  {
    path: '/certificates',
    component: EmptyRouterView,
    children: [{
      path: '',
      name: 'certificates',
      component: () => import('../views/Certificates.vue')
    }, {
      path: 'upload',
      name: 'upload-cert',
      component: () => import('../views/UploadCert.vue')
    }],
    beforeEnter: authGuard
  }
]
