import Vue from 'vue'
import VueRouter from 'vue-router'
import Profile from '@/router/Profile'
import Settings from '@/router/Settings'
import PrivateKeysRoutes from '@/router/PrivateKeys'
import CertificatesRoutes from '@/router/Certificates'
import CreateNewService from '@/router/CreateNewService'

Vue.use(VueRouter)

const baseRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '*',
    redirect: { name: 'private-keys' }
  }
]

const routes = [
  ...baseRoutes,
  ...Profile,
  ...PrivateKeysRoutes,
  ...CertificatesRoutes,
  ...Settings,
  ...CreateNewService
]

const router = new VueRouter({
  routes: routes,
  linkActiveClass: 'active'
})

export default router
