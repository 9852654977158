<template>
  <div id="app">
    <Navbar />

    <b-container fluid>
      <router-view/>
    </b-container>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'main-app',
  components: {
    Navbar,
  },
}
</script>

<style>
#app {
  font-family: 'Tablet Gothic', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
</style>
